import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { graphql, StaticQuery, withPrefix } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import { Link, FormattedMessage } from "gatsby-plugin-intl"

class CarouselLeftArrow extends React.Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--left"
        onClick={this.props.onClick}
      >
        <FaChevronLeft />
      </a>
    );
  }
}

class CarouselRightArrow extends React.Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--right"
        onClick={this.props.onClick}
      >
        <FaChevronRight />
      </a>
    );
  }
}

class CarouselIndicator extends React.Component {
  render() {
    return (
      <li>
        <a
          className={
            this.props.index == this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}

class CarouselSlide extends React.Component {
  render() {
    return (
          <li
            className={
              this.props.index == this.props.activeIndex
                ? "carousel__slide carousel__slide--active"
                : "carousel__slide"
            }
          >
            {this.props.slide.node.image != null ? (
              <div className="row align-items-center carousel-slide-outer">
                <div className="col-md">
                  <Img
                    className="rounded carousel_image pb-2"
                    fixed={this.props.slide.node.image.childImageSharp.fixed}
                    title={this.props.slide.node.title}
                    objectFit="contain"
                  />
                </div>
                <div className="col-md">
                  <p className="carousel-slide__content">
                    {this.props.slide.node.description}
                  </p>
                  <p>
                    <strong className="carousel-slide__author">
                      {this.props.slide.node.title}
                    </strong>
                  </p>
                  {this.props.slide.node.link != "" ? (
                    <p>
                      <Link to={this.props.slide.node.link}>
                        <FormattedMessage id="Read_more" />
                      </Link>
                    </p>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="row align-items-center carousel-slide-outer">
                <div className="col carousel-slide-inner">
                  <p className="carousel-slide__content">
                    {this.props.slide.node.description}
                  </p>
                  <p>
                    <strong className="carousel-slide__author">
                      {this.props.slide.node.title}
                    </strong>
                  </p>
                </div>
              </div>
            )}
          </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0
    };

  }

  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
  }

  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let slidesLength = -1;
    this.props.data.allCarouselJson.edges.forEach(element => {
      if (
        element.node.type == this.props.type &&
        element.node.lang == this.props.lng
      ) {
        slidesLength++;
      }
    });

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index
    });
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let slidesLength = -1;
    this.props.data.allCarouselJson.edges.forEach(element => {
      if (
        element.node.type == this.props.type &&
        element.node.lang == this.props.lng
      ) {
        slidesLength++;
      }
    });

    if (index === slidesLength) {
      index = -1;
    }

    ++index;
    this.setState({
      activeIndex: index
    });
  }

  render() {
    let slides = [];
    this.props.data.allCarouselJson.edges.forEach(element => {
      if (
        element.node.type == this.props.type &&
        element.node.lang == this.props.lng
      ) {
        slides.push(element);
      }
    });

    return (
          <div className="carousel my-5">
            <h2 className="title-4 text-dark text-center  w-100 mb-4">
              <FormattedMessage id="References" />
            </h2>

            <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />

            <ul className="carousel__slides h-100">
              {slides.map((slide, index) => (
                <CarouselSlide
                  key={index}
                  index={index}
                  activeIndex={this.state.activeIndex}
                  slide={slide}
                />
              ))}
            </ul>

            <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />

            <ul className="carousel__indicators">
              {slides.map((slide, index) => (
                <CarouselIndicator
                  key={index}
                  index={index}
                  activeIndex={this.state.activeIndex}
                  isActive={this.state.activeIndex == index}
                  onClick={e => this.goToSlide(index)}
                />
              ))}
            </ul>
          </div>
    );
  }
}

// Render Carousel component

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allCarouselJson {
          edges {
            node {
              id
              title
              description
              image {
                childImageSharp {
                  fixed(quality: 50, width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              type
              link
              lang
            }
          }
        }
      }
    `}
    render={data => <Carousel data={data} type={props.type} lng={props.lng} />}
  />
);
