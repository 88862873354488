import React from 'react'
import {withPrefix} from 'gatsby'
import Img from "gatsby-image/withIEPolyfill";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import { Panel, Row, Col } from 'rsuite';
import ScrollContainer from 'react-indiana-drag-scroll';


export default function MainService({node,icons,connected,i}) {
    const service = node;
    const connects = connected;
    const index = i;

    return (
        <div className="main-service">                    
            <div className="row featurette my-4 mx-md-5">
                <div className={index % 2 ? "col-md-7 order-2 order-md-2" : "col-md-7 order-2 order-md-1"}>
                    <div className="container p-md-5 my-4 my-md-0">
                        <span className="featurette-heading mb-2">
                            <Link to={service.frontmatter.path}>{service.frontmatter.title}</Link>
                        </span>
                        <div className="service-icons center-sm">
                            {service.frontmatter.icons.split(',').map((icon,i) => {
                                let match_icon = icons.find(el => el.node.title == icon);
                                return match_icon ? (
                                    <div key={i} className="service-icon center">
                                        <Img style={{ height: "2.8rem", width: "2.8rem"}} className="icon" 
                                            title={match_icon.node.desc}
                                            objectFit="contain"
                                            fixed={match_icon.node.image.childImageSharp.fixed} />
                                    </div>
                                ) : null;
                                }
                            )}
                        </div>
                        <p>{service.frontmatter.oneline}</p>
                        <p>{service.frontmatter.solution}</p>
                        <div className="link-container">
                            <Link to={service.frontmatter.path} className="button mb-3">
                                <FormattedMessage id="visit" />
                            </Link>
                        </div>
                        <ScrollContainer className="scroll-container connected-services" horizontal={true} vertical={false}>
                            {connects.length ? (
                                connects.map((con,i) => (
                                    <Col key={i} className="connected-service" md={14} lg={9} sm={24}>
                                        <Panel className="bg-light-green" shaded bordered 
                                            header={
                                            <Link className="connected-title" 
                                                to={con.frontmatter.path}>
                                                    {con.frontmatter.title}
                                                </Link>}
                                        >
                                            <span>{con.frontmatter.oneline}</span>
                                        </Panel>
                                    </Col>
                            ))) : null}
                        </ScrollContainer>
                    </div>
                </div>
                <div className={index % 2 ? "col-md-5 order-1 order-md-1 mt-md-5" : "order-1 order-md-2 col-md-5 mt-md-5"}>
                    <Img alt={service.frontmatter.title}
                        className="main_service_im"
                        fluid={service.frontmatter.illustration.childImageSharp.fluid}
                    />
                    
                </div>
            </div>
        </div>
    );
}