import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { withPrefix } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";


const Partners = ({ partnerek }) => {
  const handleOnDragStart = e => e.preventDefault();
  const responsive = {
    0: { items: 2 },
    1024: { items: 5 },
  }

  return (
    <div id="partnerek" className="my-5 py-md-5">

      <AliceCarousel
          responsive={responsive}
          autoPlayInterval={3000}
          autoPlay={true}
          mouseTracking={true}
          touchTracking={true}
          infinite={true}
          disableButtonsControls={true}
          disableDotsControls={true}
          fadeOutAnimation={true}
        >
        {partnerek.map((p, i) =>
            <Img
              key={i}
              onDragStart={handleOnDragStart}
              alt={p.node.title}
              fixed={p.node.image.childImageSharp.fixed}
              title={p.node.title}
              objectFit="contain"
            />
        )}
      </AliceCarousel>
    </div>
  )
}

export default Partners;