import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

class ContactForm extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<div className="mt-4 mb-2">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								<h3 className="title-4"><FormattedMessage id="Contact" /></h3>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-md-7 mb-4 pr-md-3">
							<form
								name="contact"
								method="post"
								action="/success"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
							>
								<input type="hidden" name="form-name" value="contact" />
								<div className="form-group">
									<label htmlFor="name"><FormattedMessage id="Name" /></label>
									<input
										type="text"
										name="name"
										id="name"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="email"><FormattedMessage id="Email" /></label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="phone"><FormattedMessage id="Phone" /></label>
									<input
										type="tel"
										name="phone"
										id="phone"
										className="form-control"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="message"><FormattedMessage id="Message" /></label>
									<textarea
										name="message"
										id="message"
										rows="3"
										className="form-control"
									></textarea>
								</div>
								<div className="row">
									<div className="form-group col-md-8">
										<div className="custom-control custom-checkbox">
											<input
												required
												type="checkbox"
												className="custom-control-input"
												id="aszfcheck"
												name="aszfcheck"
											/>
											<label
												className="custom-control-label"
												htmlFor="aszfcheck"
											>
												<FormattedMessage id="Accept_the" />
												<Link to="/terms/adatvedelem"><FormattedMessage id="T&C_link" /></Link>
											</label>
										</div>
									</div>
								</div>
								<input type="hidden" name="servicename" value="homepage" />
								<button type="submit" className="button float-right w-100">
									<FormattedMessage id="Submit" />
								</button>
							</form>
						</div>
						<div className="col-md-5 mt-md-3">
							<h4 className="title-4">Wattmanager Kft.</h4>
							<div className="call-phone">
								<strong><FormattedMessage id="Phone" />: </strong>
								<a
									href={
										"tel:" + this.props.data.site.siteMetadata.contact.phone
									}
								>
									{this.props.data.site.siteMetadata.contact.phone}
								</a>
							</div>
							<div className="call-email">
								<strong><FormattedMessage id="Email" />: </strong>
								<a
									href={`mailto:${this.props.data.site.siteMetadata.contact.email}`}
								>
									{this.props.data.site.siteMetadata.contact.email}
								</a>
							</div>
							<div className="call-phone">
								<strong><FormattedMessage id="Address" />: </strong>
								<a
									href={
										"http://maps.google.com/?q=" +
										this.props.data.site.siteMetadata.contact.cim
									}
								>
									{this.props.data.site.siteMetadata.contact.cim}
								</a>
							</div>
							<p><FormattedMessage id="Contact_before_visit" /></p>
							<a href="https://www.dnb.com/hu-hu/szolgaltatasok/bisnode-tanusitvany" target="_blank">
								<img
									src="https://certificate.hungary.dnb.com/getimage?cid=9962970&lang=en&typ=l&bg=FFFFFF&fg=000000"
									alt="Dun & Bradstreet tanusitvany" style={{ border: "1px solid #CCCCCC" }}
									oncontextmenu="return false"
									title="  The risk of business transactions with companies that possess a Dun &amp; Bradstreet Certificate is low. The rating is based on the Dun &amp; Bradstreet rating system which combines one hundred years of international experience and considers hundreds of variables. The Dun &amp; Bradstreet Certificate indicates the current status of the company which is updated daily.  " />
							</a>
							<br />

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default props => (
	<StaticQuery
		query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              cim
            }
          }
        }
      }
    `}
		render={data => <ContactForm data={data} />}
	/>
);
