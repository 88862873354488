import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby";

import MainService from "./MainService";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

// MainServices section
export default class MainServices extends React.Component {
  constructor(props){
    super(props);
  }

  getConnectedServices(service){
    let services = [];
    if(service.frontmatter.connected){
      let connected = service.frontmatter.connected.split(",");
      this.props.services.map((edge,i) => {
        if(connected.includes(edge.node.frontmatter.ident)){
          services.push(edge.node);
        }
      })
    }
    return services;
  }

  render() {
    const services = this.props.services;
    let index = 0;
    return (
          <section id="main_services" className="mt-5 mt-md-0">
            <div className="container-fluid pt-2 pt-md-4">
              <div className="w-100 justify-text-center">
                <h2 className="title-4 text-dark text-center mb-4">
                  <FormattedMessage id="Our_services" />
                </h2>
              </div>
              {services.map((edge, i) => (
                edge.node.frontmatter.lang == this.props.pageContext.intl.language ? (
                edge.node.frontmatter.featured ? (
                  <MainService key={i} node={edge.node} icons={this.props.icons}
                    connected={this.getConnectedServices(edge.node)} 
                    i={index = index + 1} 
                  />
                ) : null ) : null
              ))}
            </div>
          </section>
    );
  }
}
