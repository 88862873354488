import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Call = props => {
  return(
    <div className="call">
      <div className="call-box-top">
        <div className="call-phone">
          <strong><FormattedMessage id="Phone" />: </strong>
          <a href={"tel:" + props.data.site.siteMetadata.contact.phone}>
            {props.data.site.siteMetadata.contact.phone}
          </a>
        </div>
        <div className="call-email">
          <strong><FormattedMessage id="Email" />: </strong>
          <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
            {props.data.site.siteMetadata.contact.email}
          </a>
        </div>
        <div className="call-phone">
          <strong><FormattedMessage id="Address" />: </strong>
          <a
            target="_blank"
            rel="noopener"
            href={
              "http://maps.google.com/?q=" +
              props.data.site.siteMetadata.contact.cim
            }
          >
            {" "}
            {props.data.site.siteMetadata.contact.cim}
          </a>
        </div>
      </div>
      {props.button && (
        <div className="call-box-bottom">
          <a href="#contact" className="button">
            <FormattedMessage id="Contact" />
          </a>
        </div>
      )}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            contact {
              email
              phone
              cim
            }
          }
        }
      }
    `}
    render={data => <Call button={props.button} data={data} />}
  />
);
