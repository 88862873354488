import React from "react";
import { StaticQuery, graphql } from "gatsby";

import {
  useIntl,
  Link,
  FormattedMessage,
  injectIntl,
} from "gatsby-plugin-intl";

import { GoAlert } from "react-icons/go";

const Alert = (props) => {
  return (
    <div className='alert'>
      <Link to='/szolgaltatasok/ekr'>
        <GoAlert /> <FormattedMessage id='EKR' />
      </Link>
    </div>
  );
};

export default injectIntl(Alert);
