//import { graphql, withPrefix, Link } from 'gatsby';
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Call from "../components/Call";
import Carousel from "../components/Carousel";
import ContactForm from "../components/ContactForm";
import Team from "../components/Team";
import Partners from "../components/Partners";
import MainServices from "../components/MainServices";
import BackgroundImage from 'gatsby-background-image';
import logo from "../images/wattman_logo.webp";
import React, { Component } from "react";
import Alert from "../components/Alert";

import { FormattedMessage } from "gatsby-plugin-intl"

class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const markdown = this.props.data.allMarkdownRemark.edges;
    const partnerek = this.props.data.allPartnersJson.edges;
    const imageData = this.props.data.image.childImageSharp.fluid;
    const icons = this.props.data.allIconsJson.edges;

    return (
          <Layout bodyClass="page-home" deferGoup={true} path={this.props.location.pathname}>
            <SEO title="Wattmanager" image={this.props.data.image.publicURL} />

            <BackgroundImage fluid={imageData} style={{backgroundPosition: "75% 30%"}}
            id="home_intro" className="intro pb-4">
              <div className={"home-intro-content"}
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <img className="header-logo mb-2" alt="wattmanager logo" src={logo} />
                <h1><FormattedMessage id="slogen" /></h1>
                <span className="h2 mt-2 head-intro"><FormattedMessage id="Home_intro" /></span>
              </div>
              <div className="overlay1"></div>
              <div className="overlay2"></div>
              <Alert />
            </BackgroundImage>

            <div className={"container pt-2"}
              data-sal="slide-left"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              <Call button />
            </div>

            <MainServices services={markdown} icons={icons} {...this.props} />            

            <Carousel type="referencia" lng={this.props.pageContext.language} />
            <Team />

            <Partners partnerek={partnerek}/>

            <div id="contact" className="container">
              <ContactForm />
            </div>
          </Layout>
    );
  }
}

const homeQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/" }
        frontmatter: { active: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            ident
            path
            title
            featured
            customer
            date(formatString: "DD MMMM YYYY")
            form
            active
            lang
            desc
            keywords
            oneline
            for
            problem
            solution
            value
            lenght
            icons
            connected
            illustration {
              publicURL
              childImageSharp {
                fluid(quality: 70, maxWidth: 440) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt(pruneLength: 250)
        }
      }
    }
    allPartnersJson {
      edges {
        node {
          id
          title
          image {
            publicURL
            childImageSharp {
              fixed(quality: 80, width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allIconsJson {
      edges {
        node {
          id
          title
          image {
            publicURL
            childImageSharp {
              fixed(width: 40, quality: 10) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    image: file(relativePath: { eq: "watt_front.jpg" }) {
      publicURL
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;


export default props => (
  <StaticQuery
    query={homeQuery}
    render={data => (
      <Home location={props.location} props data={data} {...props} />
    )}
  />
)
