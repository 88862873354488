import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

// Team section
class Team extends React.Component {
	constructor(props) {
		super(props);
		this.randomInt = this.randomInt.bind(this);
	}

	randomInt(min = -15, max = 50) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	render() {
		const markdown = this.props.data.allMarkdownRemark.edges;
		let path = "/team+/" + "en" + "/";
		return (
			<section id="team">
				<div className="row team_row">
					<div className="col-md team_photos text-center">
						{markdown.map((edge, i) => (
							<div key={i} className="team_photo">

								<Img
									alt={edge.node.frontmatter.title}
									className="team-member-small rounded-circle"
									objectFit="contain"
									style={{
										height: '4rem',
										width: '4rem',
										marginRight: this.randomInt(),
										marginLeft: this.randomInt(),
										mafginTop: this.randomInt(-40, 55),
										marginBottom: this.randomInt(-40, 55)
									}}
									fixed={edge.node.frontmatter?.profile?.childImageSharp?.fixed}
								/>

							</div>
						))}
					</div>
					<div className="col-md team-text align-self-center px-2 px-md-3">
						<h3 className="title-4 text-center w-100 mb-3">
							<FormattedMessage id="Team_home_title" />
						</h3>
						<p>
							<FormattedMessage id="Team_home_intro_1" />
							<br />
							<br />
							<FormattedMessage id="Team_home_intro_2" />
							<br />
							<br />
							<FormattedMessage id="Team_home_intro_3" />
						</p>
						<div className="text-center w-100 mt-2">
							<Link to="/team">
								<span className="button text-center text-light">
									<FormattedMessage id="Team_home_button" />
								</span>
							</Link>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

// Render Team component

export default props => (
	<StaticQuery
		query={graphql`
      query {
        allMarkdownRemark(
          filter: {
				 fileAbsolutePath: { regex: "/team+/en/" }
				 frontmatter: { draft: { eq: false } }
			}
        ) {
          edges {
            node {
              html
              frontmatter {
                title
                profile {
                  childImageSharp {
                    fixed(quality: 50, width: 50) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                jobtitle
					 draft
                linkedinurl
              }
            }
          }
        }
      }
    `}
		render={data => <Team data={data} />}
	/>
);
